import React, { useEffect, useState } from "react";
import "./style.css";
// import "./font-awesome.min.css";
import "./icons.css";
import axiosInstance from "../../API/axios";
import Typewriter from "typewriter-effect";

function WebApp() {
  const improveTypes = [
    "Text Enhancer",
    "Compose Email",
    "Social Media Captions",
    "Ask Anything",
  ];
  const vacabularies = ["High", "Medium"];
  const tones = [
    "Professional",
    "Casual",
    "Funny",
    "Emotional",
    "Serious",
    "Spiritual",
    "Arrogant",
    "Patronizing",
    "Confident",
  ];
  const [languages, setLanguages] = useState([]);
  const wordLimits = [
    "25",
    "50",
    "100",
    "500",
    "1000",
    "1500",
    "2000",
    "2500",
    "No Limit",
  ];

  const improveBtnMapping = {
    "Text Enhancer": "Improve",
    "Compose Email": "Compose",
    "Ask Anything": "Answer Me",
    "Social Media Captions": "Generate Caption",
  };

  const [improveType, setImproveType] = useState("Text Enhancer");
  const [vocabulary, setVocabulary] = useState("High");
  const [tone, setTone] = useState("Professional");
  const [language, setLanguage] = useState("English");
  const [wordLimit, setWordLimit] = useState("No Limit");
  const [parentId, setParentId] = useState("");
  const [inputText, setInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [improvedText, setImprovedText] = useState(
    "Improved text will be shown here."
  );
  const [showWordLimErr, setShowWordLimErr] = useState();
  const [showPhrazifyErr, setShowPhrazifyErr] = useState();

  useEffect(() => {
    if (
      improvedText === "Improved text will be shown here." ||
      improvedText === "Please enter a text to improve."
    ) {
    } else {
      setTimeout(() => {
        let p = document.getElementById("improvedPara");
        p.scrollTop = p.scrollHeight;
        const brsCount = improvedText.split("\n").length - 1;
        if (brsCount == 0) {
          return;
        }
        let timer = setInterval(() => {
          p.scrollTop = p.scrollHeight;
          console.log(brsCount);
          console.log(
            p.children[0]?.children[0]?.innerHTML?.match(/<br[^>]*>/g)?.length
          );
          if (
            brsCount <=
            p.children[0]?.children[0]?.innerHTML?.match(/<br[^>]*>/g)?.length
          ) {
            // resetImprovedtext();
            clearInterval(timer);
          }
        }, 200);
      }, 1000);
    }
  }, [improvedText]);

  useEffect(() => {
    getLanguages();
  }, []);

  useEffect(() => {}, [languages]);
  useEffect(() => {
    console.log(vocabulary);
  }, [vocabulary]);

  const getLanguages = async () => {
    try {
      let result = await axiosInstance.get(
        `${process.env.REACT_APP_PHRAZIFY_BASE_URL}/default/queries/languages`
      );
      console.log(result);
      if (result.status === 200) {
        setLanguages(result?.data?.data?.languages);
      } else {
      }
    } catch (error) {}
  };

  const improveApi = async id => {
    try {
      setIsLoading(true);
      if (inputText === "") {
        setIsLoading(false);
        setImprovedText("Please enter a text to improve.");
        return;
      }
      console.log(id);
      const apiMapping = {
        "Text Enhancer": "improve-text",
        "Compose Email": "generate-email",
        "Ask Anything": "ask-anything",
        "Social Media Captions": "social-media-caption",
      };
      let wordLength = wordLimit !== "No Limit" ? wordLimit : undefined;

      let result = await axiosInstance.post(
        `${process.env.REACT_APP_PHRAZIFY_BASE_URL}/completion/queries/${apiMapping[improveType]}`,
        {
          inputText: inputText,
          tone: tone.toUpperCase(),
          vocabLevel: vocabulary.toUpperCase(),
          wordLength: wordLength,
          outputLanguage: language,
          parentId: id,
        }
      );
      console.log(result);
      if (result.status === 200) {
        setIsLoading(false);

        setImprovedText(result?.data?.data?.aiResponse);
        setParentId(result?.data?.data?.id);
        // setLanguages(result?.data?.data?.languages);
        const responseWordsCount = result?.data?.data?.aiResponse
          .trim()
          .split(/\s+/).length;
        if (wordLength && wordLength * 1.3 < responseWordsCount) {
          wordLengthErrorHandler();
        }
      } else {
        setShowPhrazifyErr(true);
        setTimeout(() => {
          setShowPhrazifyErr(false);
        }, 5000);
      }
    } catch (error) {
      setShowPhrazifyErr(true);
      setTimeout(() => {
        setShowPhrazifyErr(false);
      }, 5000);
    }
  };

  const wordLengthErrorHandler = () => {
    const defaultMessages = [
      "Excuse the AI's enthusiasm, it loves exceeding word limits!",
      "Our talkative AI may have added some bonus words!",
      "AI's in storytelling mode, forgot about the word limit!",
      "Too many words? Our AI just got excited sharing details!",
      "AI's generous, sprinkled some extra words for clarity!",
      "AI's being a chatterbox, lost count of words, sorry!",
      "Word limit exceeded? Blame our AI's overenthusiastic response!",
      "Our AI's extra words are like a bonus treat!",
      "AI took 'extra detail' a bit too seriously, apologies!",
      "AI's generosity with words went a bit too far!",
      "AI has a flair for drama, loves exceeding word limits!",
      "Apologies for AI's wordiness, it just loves to share!",
      "The AI may have got a bit carried away with words!",
      "Too wordy? AI just wanted to make sure you get it!",
      "AI overstepped the word limit, it's in storytelling mode!",
      "AI loves details, often forgets to count the words!",
      "AI got word-happy, gave you a little extra detail!",
      "Our AI loves to overshare, forgot about the word limit!",
      "Word limit exceeded? Our AI loves a good chat!",
      "AI thinks the more words, the merrier. Sorry for excess!",
    ];

    const randomAnswer =
      defaultMessages[Math.floor(Math.random() * defaultMessages.length)];
    setShowWordLimErr(randomAnswer);
    setTimeout(() => {
      setShowWordLimErr(false);
    }, 5000);
  };
  // const regenerateAPI = async (
  //   text,
  //   tone,
  //   vocabLevel,
  //   wordLength,
  //   language,
  //   accessToken,
  //   id,
  //   parentId,
  //   uuid,
  //   type
  // ) => {
  //   try {
  //     const response = await fetch(
  //       `${baseURI}/completion/queries/${apiMapping[type]}`,
  //       {
  //         method: "POST",
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //           Authorization: accessToken,
  //         },
  //         body: JSON.stringify({
  //           inputText: text,
  //           tone: tone.toUpperCase(),
  //           vocabLevel: vocabLevel.toUpperCase(),
  //           wordLength: wordLength ? wordLength : undefined,
  //           outputLanguage: language,
  //           parentId,
  //         }),
  //       }
  //     );

  //     const data = await response.json();

  //     if (response.status === 200) {
  //       const existingTabData = await getLocalStorage("tabData");
  //       await setLocalStorage(
  //         "tabData",
  //         JSON.stringify({
  //           ...(existingTabData ? JSON.parse(existingTabData) : {}),
  //           [uuid]: data?.data,
  //         })
  //       );
  //       return data?.data.aiResponse;
  //     } else {
  //       return `phrazifyError: ${data.message || "Sorry, Unable to improve."}`;
  //     }
  //   } catch (error) {
  //     console.error("error ", error);
  //     return "phrazifyError: Uh-oh! Our servers are taking a break. Please try again in a bit.";
  //   }
  // };

  // const resetImprovedtext = () => {
  //   let timerR = setInterval(() => {
  //     const text = document.getElementById("improvedPara").innerText;
  //     if (text.replace(/\s/g, "") === newText.replace(/\s/g, "")) {
  //       p.innerHTML = newText.replaceAll(/\n/g, "<br>");
  //     }
  //   }, 50);

  //   setTimeout(() => {
  //     clearInterval(timerR);
  //   }, 1000);
  // };

  return (
    <html style={{ height: "calc(100vh - 413.82px)" }}>
      <head>
        <title>Phrazify</title>
        {/* <link rel="stylesheet" type="text/css" rel="noopener" target="_blank" href="./css/style.css" />
        <link rel="stylesheet" type="text/css" rel="noopener" target="_blank" href="./css/font-awesome.min.css" />
        <link rel="stylesheet" type="text/css" rel="noopener" target="_blank" href="./css/icons.css" /> */}
      </head>
      <body>
        <img
          id="brand-logo"
          src="https://phrazify.s3.ap-southeast-1.amazonaws.com/icons/logo-dark.png"
          alt="logo"
          width="80"
          style={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            maxWidth: "150px",
          }}
        />
        <div id="improveTextContainer">
          <div className="langPluginModal">
            {/* Modal header */}
            <div className="langPluginModal__header">
              <h1>
                <div
                  className="langPluginModal__dropdown"
                  style={{ minWidth: "223px" }}
                >
                  <div class="dropdown">
                    <button
                      class="btn btn-secondary dropdown-toggle langPluginModal__dropdown langPluginModal__dropdownBtn langPluginModal__dropdown__label"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {improveType}
                    </button>
                    <ul class="dropdown-menu scrollable-dropdown-menu">
                      {improveTypes.map(value => {
                        return (
                          <li
                            onClick={() => {
                              setImproveType(value);
                            }}
                          >
                            <a class="dropdown-item" href="#">
                              {value}
                              <img
                                className="item-tick"
                                src="https://phrazify.s3.ap-southeast-1.amazonaws.com/icons/tick.svg"
                              />
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </h1>
            </div>
            {/* Modal body */}
            <div className="langPluginModal__body">
              <div className="row_custom">
                <div className="col-4 first-filter">
                  <label className="pr-15">Vocabulary</label>
                  <div class="dropdown">
                    <button
                      class="btn btn-secondary dropdown-toggle langPluginModal__dropdown langPluginModal__dropdownBtn langPluginModal__dropdown__label"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {vocabulary}
                    </button>
                    <ul class="dropdown-menu">
                      {vacabularies.map(value => {
                        return (
                          <li
                            onClick={() => {
                              setVocabulary(value);
                            }}
                          >
                            <a class="dropdown-item" href="#">
                              {value}
                              <img
                                className="item-tick"
                                src="https://phrazify.s3.ap-southeast-1.amazonaws.com/icons/tick.svg"
                              />
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-4 second-filter">
                  <label className="pr-15">Tone</label>
                  <div class="dropdown">
                    <button
                      class="btn btn-secondary dropdown-toggle langPluginModal__dropdown langPluginModal__dropdownBtn langPluginModal__dropdown__label"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {tone}
                    </button>
                    <ul class="dropdown-menu scrollable-dropdown-menu">
                      {tones.map(value => {
                        return (
                          <li
                            onClick={() => {
                              setTone(value);
                            }}
                          >
                            <a class="dropdown-item" href="#">
                              {value}
                              <img
                                className="item-tick"
                                src="https://phrazify.s3.ap-southeast-1.amazonaws.com/icons/tick.svg"
                              />
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-4 third-filter">
                  <label className="pr-15">Language</label>
                  <div class="dropdown">
                    <button
                      class="btn btn-secondary dropdown-toggle langPluginModal__dropdown langPluginModal__dropdownBtn langPluginModal__dropdown__label"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {language}
                    </button>
                    <ul class="dropdown-menu scrollable-dropdown-menu">
                      {languages.map(value => {
                        return (
                          <li
                            onClick={() => {
                              setLanguage(value);
                            }}
                          >
                            <a class="dropdown-item" href="#">
                              {value}
                              <img
                                className="item-tick"
                                src="https://phrazify.s3.ap-southeast-1.amazonaws.com/icons/tick.svg"
                              />
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="improveTextWrapper">
                <textarea
                  rows="3"
                  cols="20"
                  id="inputText"
                  placeholder="Enter your text here"
                  autoFocus
                  onChange={e => {
                    setInputText(e.target.value);
                    console.log(e.target.value);
                  }}
                ></textarea>
                <div className="improveTextWrapper__footer">
                  <div className="word-limit">
                    <label className="pr-15">Word Limit</label>
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle langPluginModal__dropdown langPluginModal__dropdownBtn langPluginModal__dropdown__label"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {wordLimit}
                      </button>
                      <ul class="dropdown-menu scrollable-dropdown-menu">
                        {wordLimits.map(value => {
                          return (
                            <li
                              onClick={() => {
                                setWordLimit(value);
                              }}
                            >
                              <a class="dropdown-item" href="#">
                                {value}
                                <img
                                  className="item-tick"
                                  src="https://phrazify.s3.ap-southeast-1.amazonaws.com/icons/tick.svg"
                                />
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="main-buttons">
                    <div className="buttons-wrapper">
                      {parentId === "" ? (
                        <></>
                      ) : (
                        <button
                          className="baseBackgroundColor__btn"
                          id="regenerate"
                          onClick={() => {
                            improveApi(parentId);
                          }}
                          // style={{ display: "none" }}
                        >
                          {/* <span className="flat-button__text">
                          <img
                            id="regenerate-img"
                            style={{ width: '19px', marginRight: '6px' }}
                            src="https://phrazify.s3.ap-southeast-1.amazonaws.com/icons/regenerate.svg"
                          />
                          Regenerate
                        </span> */}
                          Regenerate
                        </button>
                      )}

                      <button
                        className="improveTextWrapper__btn"
                        id="generate"
                        onClick={() => {
                          improveApi("");
                        }}
                      >
                        {improveBtnMapping[improveType]}
                      </button>
                      <div></div>
                    </div>
                    {parentId === "" ? (
                      <></>
                    ) : (
                      <div
                        className="btn-helper-text"
                        // style={{ display: "none" }}
                      >
                        {" "}
                        "Regenerate" to continue chat with new prompt, "Improve"
                        for new thoughts.
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="rendered-content">
                <p
                  id="improvedPara"
                  className={
                    improvedText.length > 100 || improvedText.includes("\n")
                      ? "large-output"
                      : "small-output"
                  }
                >
                  {/* {improvedText === "Improved text will be shown here." ||
                  improvedText === "Please enter a text to improve." ? (
                    <>Improved text will be shown here.</>
                  ) : (
                    <Typewriter text={improvedText} delay={10} />
                  )} */}
                  {isLoading ? (
                    <button class="phz-loading">
                      <span class="spinner"></span>
                    </button>
                  ) : improvedText === "Improved text will be shown here." ||
                    improvedText === "Please enter a text to improve." ? (
                    improvedText
                  ) : (
                    <Typewriter
                      onInit={typewriter => {
                        typewriter
                          .changeDelay(10)
                          .typeString(improvedText.replaceAll(/\n/g, "<br>"))
                          // .callFunction(() => {
                          //   console.log("String typed out!");
                          // })
                          // .pauseFor(2500)
                          // .deleteAll()
                          // .callFunction(() => {
                          //   console.log("All strings were deleted");
                          // })
                          .start();
                      }}
                    />
                  )}
                </p>
                {/* <p id="improvedParaHidden" style={{ display: "none" }}></p> */}
                {showWordLimErr && (
                  <div id="word-limit-error">{showWordLimErr}</div>
                )}
                {showPhrazifyErr && (
                  <div id="phrazify-error">
                    Uh-oh! Our servers are taking a break. Please try again in a
                    bit.
                  </div>
                )}
                <div className="buttons text-right">
                  <button
                    id="copy"
                    disabled={
                      improvedText === "Improved text will be shown here." ||
                      improvedText === "Please enter a text to improve."
                        ? true
                        : false
                    }
                    className="flat-button"
                    onClick={async () => {
                      await navigator.clipboard.writeText(improvedText);
                    }}
                  >
                    <span className="flat-button__text">
                      <img
                        id="copy-img"
                        src="https://phrazify.s3.ap-southeast-1.amazonaws.com/icons/copy.svg"
                      />
                      Copy
                    </span>
                  </button>
                </div>
              </div>
              {/* Modal footer */}
              <div className="langPluginModal__footer">
                <span>
                  <img
                    id="brand-logo"
                    src="https://phrazify.s3.ap-southeast-1.amazonaws.com/icons/logo-dark.png"
                    alt="logo"
                    width="80"
                  />
                </span>
                <span className="langPluginModal__footer__copyright">
                  &copy; All rights reserved
                </span>
              </div>
            </div>
          </div>
        </div>
        <script src="./js/blank.js"></script>
        <script src="./js/typing.js"></script>
      </body>
    </html>
  );
}

export default WebApp;
