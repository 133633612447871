export const accordionData = [
  {
    title: 'What is the main purpose of the Phrazify Chrome Extension?',
    content: `Phrazify is a writing enhancement tool that helps to improve your text, emails, and generates social media captions. It also provides comprehensive responses to your queries and offers a text to speech feature. All of these features can be customized according to your needs.`,
  },
  {
    title: 'How does the Phrazify extension work?',
    content: `Once installed on your Chrome browser, Phrazify accepts text inputs from you and then generates improved versions of the text, enhancing grammar, vocabulary, or sentence structure. The improvements are made according to your specified filters such as English level, word limit, and tone.`,
  },
  {
    title: 'What are the key features of the Phrazify extension?',
    content: `The key features are: improve text, improve email, ask anything, generate social media captions, and text to speech. All these features can be adjusted by the user's preference for English level, word limit, and tone.`,
  },
  {
    title: 'How does Phrazify maintain the privacy of my data?',
    content: `Phrazify ensures the utmost confidentiality and security of your data. Your text inputs and generated outputs remain private, allowing you to express freely with peace of mind.`,
  },
  {
    title: 'What are the different subscription plans available for Phrazify?',
    content: `Phrazify offers a range of subscription plans from a Free Plan to Premium Plan. The plans are based on word count limits per day and vary in price. You can choose a plan that suits your usage and budget best.`,
  },
  {
    title: "How does the 'Ask Anything' feature work?",
    content: `The 'Ask Anything' function allows users to ask questions. The system uses a large language model or AI trained on a diverse range of topics to produce the best possible answer.`,
  },
  {
    title: "How do the 'Filters' work in Phrazify?",
    content: `The 'Filters' function allows you to customize the output of other functions based on your needs. You can select your desired English level, word limit, and the tone of the text. These filters are applied to your input text to shape the generated output accordingly.`,
  },
  {
    title: 'What happens if I exceed my monthly word limit?',
    content: `If you exceed your monthly word limit, you'll need to upgrade to a higher subscription plan in order to continue using the Phrazify services.`,
  },
  {
    title: 'Can I change my subscription plan later?',
    content: `Yes, you can upgrade or downgrade your subscription plan as per your needs at any time.`,
  },
  {
    title: 'How are words deducted from my monthly limit in Phrazify?',
    content: `Words are deducted from your monthly limit based on both the input text you provide and the output text generated by Phrazify. This means that if you input 100 words and receive an output of 120 words, a total of 220 words would be deducted from your monthly limit.`,
  },
];
