import React, { useState } from 'react';
import { Button, Modal, message } from 'antd';
import ChangePassword from '../ChangePassword/ChangePassword';
import secureLocalStorage from 'react-secure-storage';
import { AuthContext } from '../../App';
import { setCookie } from '../../util/cookies';

const UserProfile = ({ name, email }) => {
  let value = React.useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    Modal.destroyAll();
    setIsModalOpen(false);
  };
  return (
    <div className="whiteWrapper">
      <div className="userProfile">
        <div className="userProfile__content">
          <span className="userProfile__welcome">Welcome</span>
          <h1 className="userProfile__heading">{name}</h1>
          <span className="userProfile__email">{email}</span>
        </div>
        <a href="#" onClick={showModal} className="userProfile__link">
          Change password
        </a>
        <Modal width={400} open={isModalOpen} footer={null} onCancel={handleModalClose} destroyOnClose={true}>
          <ChangePassword handleModalClose={handleModalClose} />
        </Modal>
        <div
          className="userProfile__logout"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            secureLocalStorage.clear();
            setCookie('phrazify-accessToken', '', -1);
            setCookie('phrazify-userId', '', -1);
            setCookie('phrazify-refreshToken', '', -1);
            value.setIsAuthenticated(false);
            localStorage.setItem('isAuthenticated', false);
          }}
        >
          Logout
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
