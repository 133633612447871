import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import AllPlans from '../../components/AllPlans/AllPlans';
import axiosInstance from '../../API/axios';
import secureLocalStorage from 'react-secure-storage';
import UserProfile from '../../components/UserProflie/UserProfile';
import CurrentPlanDetails from '../../components/CurrentPlanDetails/CurrentPlanDetails';
import { useNavigate } from 'react-router-dom';
import AddExtension from '../../components/AddExtension/AddExtension';
import { getCookie, setCookie } from '../../util/cookies';

function Dashboard() {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState();
  const [userId, setUserId] = useState('');
  const [isExtInstalled, setIsExtInstalled] = useState(false);

  useEffect(() => {
    const id = getCookie('phrazify-userId');
    console.log('id: ', id, typeof id);

    if (!id) {
      navigate('/signin');
      secureLocalStorage.clear();
      setCookie('phrazify-accessToken', '', -1);
      setCookie('phrazify-userId', '', -1);
      setCookie('phrazify-refreshToken', '', -1);
    } else {
      getUserDetails(id);
      setUserId(id);
    }
  }, [userId]);

  // useEffect(() => {
  //   if (userId) {
  //     getUserDetails(userId);
  //   }
  // }, [userId]);

  useEffect(() => {
    var isExtInstalledDiv = document.getElementById('ext-dev-id');
    if (JSON.stringify(isExtInstalledDiv) != 'null') {
      setIsExtInstalled(true);
    }
  });

  const getUserDetails = async (userId) => {
    try {
      let result = await axiosInstance.get(`${process.env.REACT_APP_PHRAZIFY_BASE_URL}/user/queries/user?id=${userId}`);

      if (result.status === 200) {
        setUserDetails(result?.data?.data?.user);
        if (!result?.data?.data?.user.emailVerified) {
          navigate('/verify-email-pending');
        }
      } else {
        navigate('/signin');
        secureLocalStorage.clear();
        setCookie('phrazify-accessToken', '', -1);
        setCookie('phrazify-userId', '', -1);
        setCookie('phrazify-refreshToken', '', -1);
      }
    } catch (error) {
      navigate('/signin');
      secureLocalStorage.clear();
      setCookie('phrazify-accessToken', '', -1);
      setCookie('phrazify-userId', '', -1);
      setCookie('phrazify-refreshToken', '', -1);
      // message.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="layoutPhrazify__body">
        {userDetails && (
          <div className="main-container">
            <div className="main-container__side-nav">
              <UserProfile name={userDetails.name} email={userDetails.email} />
            </div>
            <div className="main-container__content-section">
              {isExtInstalled ? null : <AddExtension />}
              <CurrentPlanDetails
                planName={userDetails?.plansHistory?.plan?.name}
                validTill={userDetails?.plan?.validTill.slice(0, 10)}
                wordsLeft={userDetails?.plan?.tokensLeft}
                lastPurchaseDate={userDetails?.plansHistory?.createdAt.slice(0, 10)}
                isAboutToExpire={userDetails?.plan?.isAboutToExpire}
              />
              <AllPlans activePlanId={userDetails?.plan?.plan?._id}></AllPlans>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Dashboard;
