import React from 'react';
import { Button, Form, Input, Modal, message } from 'antd';
import axiosInstance from '../../API/axios';

const ChangePassword = ({ handleModalClose }) => {
  const changePasswordRequest = async (passwordChangeData) => {
    try {
      let result = await axiosInstance.post(
        `${process.env.REACT_APP_PHRAZIFY_BASE_URL}/user/mutations/change-password`,
        passwordChangeData,
      );

      if (result.status === 200) {
        message.success(result.data.message);
        handleModalClose();
        Modal.destroyAll();
      }
    } catch (error) {
      let loadingButton = document.getElementById('loadingButton');
      loadingButton.innerHTML = 'Submit';
      loadingButton.disabled = false;
      console.log(error);
      message.error(error?.data?.message);
    }
  };
  const onFinish = (values) => {
    let loadingButton = document.getElementById('loadingButton');
    loadingButton.innerHTML = '<span class="spinner"></span>';
    loadingButton.style.backgroundImage = 'linear-gradient(to top, #1991eb, #2da1f8)';
    loadingButton.disabled = true;

    let { oldPassword, newPassword } = values;
    changePasswordRequest({ oldPassword, newPassword });
  };

  return (
    <div className="loginWin__signIn" style={{ width: '100%', padding: '1.5rem' }}>
      <h1>Change Password</h1>
      <Form name="register" layout="vertical" onFinish={onFinish} scrollToFirstError>
        <Form.Item
          name="oldPassword"
          label="Old Password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="newPassword"
          label="New Password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
            () => ({
              validator(_, value) {
                if (!value || value.length > 5) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Password length must be at least 6 characters long!'));
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm New Password"
          dependencies={['newPassword']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The new password that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <button id="loadingButton" className="btn__primary" htmlType="submit">
            Submit
          </button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default ChangePassword;
