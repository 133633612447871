import React, { useEffect, useState } from 'react';
import imgTick from '../../assets/images/img-tick.png';
import imgCross from '../../assets/images/img-cross.png';
import { useNavigate, useSearchParams } from 'react-router-dom';

const PaymentConfirmation = () => {
  const [secondsLeft, setSecondsLeft] = useState(10);
  const [successNotification, setSuccessNotification] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    let paymentSuccess = searchParams.get('success');
    let session_id = searchParams.get('session_id');
    let paymentCanceled = searchParams.get('canceled');

    if (session_id) {
      if (paymentSuccess == 'true') {
        setSuccessNotification(true);
      } else {
        setSuccessNotification(false);
      }
    } else if (paymentCanceled == 'true') {
      setSuccessNotification(false);
    } else {
      navigate('/dashboard');
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (secondsLeft > 0) {
        setSecondsLeft(secondsLeft - 1);
      }
      if (secondsLeft === 0) {
        navigate('/dashboard');
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [secondsLeft]);

  return (
    <div className="layoutPhrazify__body" style={{ backgroundColor: '#f6f8fa' }}>
      <div className="main-container">
        <div className="whiteWrapper" style={{ margin: 'auto' }}>
          {successNotification ? (
            <div className="payment-message-box">
              <div className="payment-message-img-container">
                <img src={imgTick} height="100px" alt="Success" className="payment-message-img"></img>
              </div>
              <div className="payment-message-content">
                <div className="payment-message-title">Payment Successful.</div>
                <div className="payment-message-description">
                  Thank you for your payment. An Automated payment receipt will be sent to your registered email.
                </div>
                <button
                  style={{ width: '215px' }}
                  className="btn__primary"
                  onClick={() => {
                    window.location.replace(`https://phrazify.com/dashboard`);
                  }}
                >
                  Redirect to Dashboard
                </button>
                Note: You will be redirected to dashboard in {secondsLeft} seconds.
              </div>
            </div>
          ) : (
            <div className="payment-message-box">
              <div className="payment-message-img-container" style={{ backgroundColor: '#e3747e' }}>
                <img src={imgCross} height="100px" alt="Faliure" className="payment-message-img"></img>
              </div>
              <div className="payment-message-content">
                <div className="payment-message-title">Oh no, your payment failed.</div>
                <div className="payment-message-description">
                  Don’t worry, your money is safe! If money was debited from your account, it will be refunded
                  automatically in 5-7 days. <br /> <br />
                  <b>Please try using another Payment method.</b>
                </div>
                <button
                  style={{ width: '215px' }}
                  className="btn__primary"
                  onClick={() => {
                    navigate('/dashboard');
                  }}
                >
                  Redirect to Dashboard
                </button>
                Note: You will be redirected to dashboard in {secondsLeft} seconds.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentConfirmation;
