import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Bottombar() {
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   console.log(location);
  // });

  return (
    <>
      {location.pathname.includes("/app") ? (
        <></>
      ) : (
        <div className="layoutPhrazify__darkStrip">
          <h4 className="layoutPhrazify__darkStrip__heading">We Are Here</h4>
          <p className="layoutPhrazify__darkStrip__tagline">
            Need support or have a suggestion? Email us at{" "}
            <a
              style={{
                color: "inherit",
                textDecoration: "inherit",
                fontWeight: 600,
              }}
              href="mailto:support@phrazify.com"
            >
              support@phrazify.com
            </a>
          </p>
          <p className="layoutPhrazify__darkStrip__nrmlTxt">
            24-hour response commitment because we care
          </p>
        </div>
      )}

      <div
        className={`layoutPhrazify__footer ${
          location.pathname.includes("/app")
            ? "layoutPhrazify__footer_margin"
            : ""
        }`}
      >
        <div className="layoutPhrazify__footer__branding">
          <img
            src="https://phrazify.s3.ap-southeast-1.amazonaws.com/icons/logo-dark.png"
            alt="website logo"
          ></img>
          © All rights reserved to Phrazify 2023
        </div>
        <ul className="layoutPhrazify__footer__nav">
          <li
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/privacy-policy");
            }}
          >
            Privacy Policy
          </li>
          <li
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/terms-and-conditions");
            }}
          >
            Terms of use
          </li>
          <li
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/refund-policy");
            }}
          >
            Subscription policy
          </li>
        </ul>
      </div>
    </>
  );
}

export default Bottombar;
