import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { message } from 'antd';
import { getCookie, setCookie } from '../../util/cookies';
let retryCheck = false;
// export const BaseUrl = process.env.REACT_APP_PHRAZIFY_BASE_URL;
export const BaseUrl = process.env.REACT_APP_PHRAZIFY_BASE_URL;

const axiosInstance = axios.create({
  baseURL: BaseUrl,
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (
      response.config.url === `${process.env.REACT_APP_PHRAZIFY_BASE_URL}/user/mutations/update_session` &&
      response.request.status === 200
    ) {
      retryCheck = false;
    }
    return response;
  },
  async (error) => {
    let originalConfig = error.config;
    // if (error?.response?.status === 400) {
    //   secureLocalStorage.clear();
    //   window.location.href = '/signin';
    //   message.info('Please log in again');
    // }
    if (error?.response?.status === 401) {
      if (
        originalConfig.url !== `${process.env.REACT_APP_PHRAZIFY_BASE_URL}/user/mutations/update_session` &&
        error.response
      ) {
        // Access Token was expired
        if (error.response.status === 401 && !retryCheck) {
          retryCheck = true;

          try {
            const rs = await axiosInstance.post(
              `${process.env.REACT_APP_PHRAZIFY_BASE_URL}/user/mutations/update_session`,
              {
                refreshToken: getCookie('phrazify-refreshToken'),
              },
            );

            setCookie('phrazify-accessToken', rs.data.data.session.accessToken, 30);
            setCookie('phrazify-refreshToken', rs.data.data.session.refreshToken, 30);

            return axiosInstance(originalConfig);
          } catch (_error) {
            secureLocalStorage.clear();
            setCookie('phrazify-accessToken', '', -1);
            setCookie('phrazify-userId', '', -1);
            setCookie('phrazify-refreshToken', '', -1);
            window.location.href = '/signin';
            message.info('Please log in again');
            return Promise.reject(_error);
          }
        }
      }
    }
    return Promise.reject((error.response && error.response) || 'Something went wrong!');
  },
);

axiosInstance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    if (config.url !== `${process.env.REACT_APP_PHRAZIFY_BASE_URL}/user/mutations/update_session`) {
      config.headers.Authorization = `Bearer ${getCookie('phrazify-accessToken')}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export default axiosInstance;
