import { Button, Card, message } from 'antd';
import React from 'react';
import secureLocalStorage from 'react-secure-storage';
import axiosInstance from '../../API/axios';

const PricingCard = ({ planDetails, IsCurrentlyActive, isUserCurrencyINR }) => {
  let { name, wordsPerMonth, monthlyPriceINR, monthlyPriceDollar, productId, description } = planDetails;

  const createCheckoutSession = async (key) => {
    let loadingButton = document.getElementById(`loadingButton-${key}`);
    loadingButton.innerHTML = '<span class="spinner"></span>';
    loadingButton.style.backgroundImage = 'linear-gradient(to top, #1991eb, #2da1f8)';
    loadingButton.disabled = true;
    try {
      const isCurrencyINR = secureLocalStorage.getItem('isUserCurrencyINR');
      let result = await axiosInstance.post(
        `${process.env.REACT_APP_PHRAZIFY_BASE_URL}/payment/mutations/create-checkout-session`,
        {
          productId: key,
          currency: isCurrencyINR && isCurrencyINR === 'true' ? 'inr' : 'usd',
        },
      );
      if (result.status === 200) {
        // message.success(result.data.message)
        // let loadingButton = document.getElementById('loadingButton')
        // loadingButton.innerHTML = 'Upgrade';
        // loadingButton.disabled = false
        window.location.replace(result.data.data.sessionUrl);
      }
    } catch (error) {
      // loadingButton = document.getElementById('loadingButton'
      console.log(error);
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <div className="apCard">
      {/* <Card style={{padding:"1rem .5rem", backgroundColor: "#1677FF", color: "white", textAlign:"center"}}> */}
      {IsCurrentlyActive ? (
        <div className="apCard__heading">
          <em className=".icon-ic-current-plan selectedIcon">
            <span className="path1"></span>
            <span className="path2"></span>
          </em>
          <h2>{name}</h2>
        </div>
      ) : (
        <div className="apCard__heading">
          <h2>{name}</h2>
        </div>
      )}
      <div className="apCard__body">
        <div className="apCard__price">
          {isUserCurrencyINR ? '₹ ' : '$ '}
          {isUserCurrencyINR ? monthlyPriceINR.toLocaleString('en-IN') : monthlyPriceDollar.toLocaleString('en-IN')}
          <span className="per-month"> /month</span>
        </div>
        {description.map(function (object, i) {
          return object.includes('words limit per month') ? (
            <p>
              <span style={{ fontSize: '0.85rem', fontWeight: '600' }}>{wordsPerMonth.toLocaleString('en-IN')}</span>{' '}
              <span>words limit per month.</span>
            </p>
          ) : (
            <p>{object}</p>
          );
        })}
      </div>
      {IsCurrentlyActive ? (
        <button disabled className="btn__primary">
          Current Plan
        </button>
      ) : (
        <button
          id={'loadingButton-' + productId}
          className="btn__primary"
          onClick={(e) => {
            e.preventDefault();
            createCheckoutSession(productId);
          }}
        >
          Upgrade
        </button>
      )}
    </div>
  );
};

export default PricingCard;
