import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, message } from 'antd';
import axios from 'axios';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../App';
import secureLocalStorage from 'react-secure-storage';
import axiosInstance from '../API/axios';
import VerifyEmailPending from './VerifyEmailPending';
import '../styles/loader.css';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    setToken(searchParams.get('t').replace(/ /g, '+'));
    setUser(searchParams.get('u').replace(/ /g, '+'));
  });

  useEffect(() => {
    if (user && token) {
      verifyUserEmail();
    }
  }, [user, token]);

  const verifyUserEmail = async () => {
    try {
      let result = await axios.post(`${process.env.REACT_APP_PHRAZIFY_BASE_URL}/user/mutations/verify-email`, {
        token: token,
        user: user,
      });

      if (result.status === 200) {
        message.success(result.data.message);
        navigate('/dashboard');
      }
    } catch (error) {
      console.log('error comes in error', error?.response?.data?.message);
      message.error(error?.response?.data?.message || 'Unable to verify the link.');
      navigate('/verify-email-pending');
    }
  };

  return (
    <>
      <div className="layoutPhrazify__body">
        <div className="main-container">
          <div className="verify-in-progress-container">
            <h1 style={{ margin: 'auto' }}>Verification in Progress</h1>
            <div className="section3" id="loader">
              <button className="loading">
                <span className="spinner"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
