import React, { useState } from 'react';

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      {isActive ? (
        <div className="faqs-wrapper__item active">
          <div className="question" onClick={() => setIsActive(!isActive)}>
            {title}
            <em className="icon-ic-minus"></em>
          </div>
          <div className="answer">{content}</div>
        </div>
      ) : (
        <div className="faqs-wrapper__item">
          <div className="question" onClick={() => setIsActive(!isActive)}>
            {title}
            <em className="icon-ic-plus"></em>
          </div>
          <div className="answer">{content}</div>
        </div>
      )}
    </>
  );
};

export default Accordion;
