import React, { useEffect } from 'react';
import { Button, Card, Checkbox, Form, Input, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { AuthContext } from '../App';
import Bottombar from '../containers/PublicContainer/Bottombar';
import Navbar from '../containers/PublicContainer/Navbar';
import { setCookie } from '../util/cookies';

const SignUp = () => {
  const navigate = useNavigate();

  let value = React.useContext(AuthContext);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_PHRAZIFY_RECAPTCHA_KEY}`,
      function () {
        console.log('Script loaded!');
      },
    );
  }, []);

  const userSignUp = async (registrationData) => {
    try {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_PHRAZIFY_RECAPTCHA_KEY, {
            action: 'submit',
          })
          .then(async (token) => {
            try {
              let result = await axios.post(`${process.env.REACT_APP_PHRAZIFY_BASE_URL}/user/mutations/signup`, {
                ...registrationData,
                gRecaptchaResponse: token,
              });

              if (result.status === 200) {
                message.success(result.data.message);
                message.success('Please login with same credentials on the extension.');
                setCookie('phrazify-accessToken', result.data.data.session.accessToken, 30);
                setCookie('phrazify-userId', result.data.data.user._id, 30);
                setCookie('phrazify-refreshToken', result.data.data.session.refreshToken, 30);
                // secureLocalStorage.setItem('name', result.data.data.user.name);
                // secureLocalStorage.setItem('email', result.data.data.user.email);
                navigate('/dashboard');
                value.setIsAuthenticated('true');
                localStorage.setItem('isAuthenticated', 'true');
              }
            } catch (e) {
              let loadingButton = document.getElementById('loadingButton');
              loadingButton.innerHTML = 'Sign Up';
              loadingButton.disabled = false;
              message.error(e?.response?.data?.message);
            }
          });
      });
    } catch (error) {
      let loadingButton = document.getElementById('loadingButton');
      loadingButton.innerHTML = 'Sign Up';
      loadingButton.disabled = false;
      message.error(error?.response?.data?.message);
    }
  };
  const onFinish = (values) => {
    let loadingButton = document.getElementById('loadingButton');
    loadingButton.innerHTML = '<span class="spinner"></span>';
    loadingButton.style.backgroundImage = 'linear-gradient(to top, #1991eb, #2da1f8)';
    loadingButton.disabled = true;
    let { email, name, password } = values;
    userSignUp({ email, name, password });
  };

  return (
    <>
      <div className="layoutPhrazify__body">
        <div className="main-container">
          <div className="loginWin">
            <div className="loginWin__imgWrap"></div>
            <div className="loginWin__signIn">
              <h1>Sign Up</h1>
              <Form layout="vertical" name="register" onFinish={onFinish} scrollToFirstError>
                <Form.Item
                  name="name"
                  label="Name"
                  tooltip="This will be your display Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Name!',
                      // whitespace: true
                    },
                    {
                      pattern: new RegExp(/[a-zA-Z][a-zA-Z ]{0,2}[a-zA-Z]$/g),
                      message: 'Name should contain only alphabets!',
                    },
                  ]}
                >
                  <Input placeholder="John Smith" />
                </Form.Item>

                <Form.Item
                  name="email"
                  label="E-mail"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Please input your E-mail!',
                    },
                  ]}
                >
                  <Input placeholder="john@example.com" />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      pattern: new RegExp(/^\S*$/g),
                      message: 'No spaces allowed in the password!',
                    },
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    () => ({
                      validator(_, value) {
                        if (!value || value.length > 5) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Password length must be at least 6 characters long!'));
                      },
                    }),
                  ]}
                  hasFeedback
                >
                  <Input.Password placeholder="Enter your password" />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('The new password that you entered do not match!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirm your password" />
                </Form.Item>

                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject(new Error('Should accept terms of service')),
                    },
                  ]}
                >
                  <Checkbox>
                    I have read and agree to the{' '}
                    <Link to="/terms-and-conditions">
                      <a href="/">terms of service</a>
                    </Link>
                  </Checkbox>
                </Form.Item>

                <Form.Item>
                  <button id="loadingButton" className="btn__primary" htmlType="submit">
                    Sign Up
                  </button>
                </Form.Item>
                <div
                  className="loginWin__signup"
                  onClick={() => {
                    navigate('/signin');
                  }}
                >
                  Already Have an account? <a href="">Sign in</a>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignUp;
