import { Alert, Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const AddExtension = () => {
  return (
    <div className="whiteWrapper">
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }} className="whiteWrapper__body">
        {/* <Alert
                banner={true}
                showIcon={false}
                style={{
                  textAlign: 'center',
                  backgroundColor: '#feff3d',
                  color: '#000',
                  marginBottom: '0',
                  width: '100%',
                  zIndex: '10',
                }}
                message={
                  <div >
                    Click{' '}
                    <Link className="banner-link" to="https://chrome.google.com/webstore/detail/phrazify/mmkelknopjilcafbbdjnjagngibnghgn">
                      here
                    </Link>
                    {' '}to add this extension to Google Chrome
                  </div>
                }
                type="info"
                closable
              >
                message
              </Alert> */}
        <Link
          to="https://chrome.google.com/webstore/detail/phrazify/mmkelknopjilcafbbdjnjagngibnghgn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="btn__primary">Add Phrazify to Google Chrome</button>
        </Link>
      </div>
    </div>
  );
};

export default AddExtension;
