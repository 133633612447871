import React, { useEffect } from 'react';
import Bottombar from '../containers/PublicContainer/Bottombar';
import { Parser } from 'html-to-react';

const rawHTML = `<!DOCTYPE html>
<html>
  <head>
    <meta charset="UTF-8" />
    <title>Refund & Cancellation Policy</title>
    <style>
      body {
        font-family: Arial, sans-serif;
        margin: 0;
      }

      h1 {
        text-align: center;
        font-size: 24px;
        margin-bottom: 20px;
      }

      h2 {
        font-size: 18px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 16px;
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 10px;
      }

      ol {
        margin-left: 20px;
      }

      .highlight {
        font-weight: bold;
      }

      .contact-info {
        margin-top: 40px;
      }
    </style>
  </head>
  <body>
    <h1>Refund & Cancellation Policy</h1>
    <h2>Introduction</h2>
    <p><strong>1.1 Purpose</strong></p>
    <p>
      The Refund & Cancellation Policy outlines the guidelines and procedures for refunds and cancellations of subscription plans for the
      Phrazify Chrome Extension. This policy aims to ensure transparency and provide clear instructions to users regarding their options for
      refunds and cancellations.
    </p>
    <p><strong>1.2 Scope</strong></p>
    <p>This policy applies to all users who have subscribed to the paid plans of the Phrazify Chrome Extension.</p>
    <h2>Subscription Plans</h2>
    <h3>2.1 Free Plan</h3>
    <p>
      The Free Plan does not require any payment and does not have a refund or cancellation process. Users can use the Free Plan without any
      financial commitments.
    </p>
    <h3>2.2 Paid Plans</h3>
    <p>
      Paid plans, including Starter, Standard, Premium, and Ultimate, require users to pay a specified amount for access to premium features
      and increased word count limits. The refund and cancellation policy is applicable to these paid plans.
    </p>
    <h2>Refund Policy</h2>
    <h3>3.1 Eligibility for Refunds</h3>
    <p>Users may be eligible for a refund if they meet the following conditions:</p>
    <ol>
      <li>The refund request is made within the applicable refund period.</li>
      <li>The user has not exceeded the word count limit for the current billing cycle.</li>
      <li>The user has not violated the terms and conditions of the Phrazify Chrome Extension.</li>
    </ol>
    <h3>3.2 Refund Period</h3>
    <p>The refund period for each subscription plan is as follows:</p>
    <ul>
      <li>Starter Plan: 7 days from the date of purchase</li>
      <li>Standard Plan: 7 days from the date of purchase</li>
      <li>Premium Plan: 7 days from the date of purchase</li>
      <li>Ultimate Plan: 7 days from the date of purchase</li>
    </ul>
    <h3>3.3 Refund Process</h3>
    <p>To request a refund, users must follow these steps:</p>
    <ol>
      <li>
        Contact Phrazify Support via email at <a href="mailto:support@phrazify.com">support@phrazify.com</a> within the refund period.
      </li>
      <li>Provide the necessary details, including the subscription plan, purchase date, and reason for the refund request.</li>
      <li>The Phrazify Support team will review the request and respond within a reasonable timeframe.</li>
      <li>If the refund request is approved, the refund will be processed using the original payment method.</li>
    </ol>
    <h3>3.4 Refund Amount</h3>
    <p>
      The refund amount will be determined based on the remaining unused portion of the subscription plan. The refund will exclude any
      applicable taxes or transaction fees incurred during the initial purchase.
    </p>
    <h2>Cancellation Policy</h2>
    <h3>4.1 Cancellation Process</h3>
    <p>Users can cancel their subscription to the paid plans at any time by following these steps:</p>
    <ol>
      <li>Log in to the Phrazify Chrome Extension using the registered account.</li>
      <li>Go to the account settings or subscription management section.</li>
      <li>Select the option to cancel the subscription.</li>
      <li>Confirm the cancellation when prompted.</li>
    </ol>
    <h3>4.2 Effective Date of Cancellation</h3>
    <p>
      The cancellation of the subscription plan will be effective immediately upon confirmation. Users will retain access to the premium
      features and increased word count limits until the end of the current billing cycle.
    </p>
    <h3>4.3 No Partial Refunds for Cancellations</h3>
    <p>
      Cancellation of a subscription plan does not entitle users to a partial refund for the unused portion of the billing cycle. Users will
      continue to have access to the benefits of the subscription plan until the end of the current billing cycle.
    </p>
    <h3>4.4 Auto-Renewal Cancellation</h3>
    <p>
      Cancellation of a subscription plan will prevent auto-renewal for the next billing cycle. Users will not be charged for subsequent
      billing cycles after the cancellation is processed.
    </p>
    <h2>Change Management Process</h2>
    <p>
      This Refund & Cancellation Policy may be revised and updated as necessary. Any changes to this policy will be communicated to users
      through appropriate channels and will be effective immediately upon posting the updated policy.
    </p>
    <div class="contact-info">
      <h2>Contact Information</h2>
      <p>
        For any questions or concerns regarding the Refund & Cancellation Policy, users can contact Phrazify Support via email at
        <a href="mailto:support@phrazify.com">support@phrazify.com</a>. The support team will respond within 24 hours and provide assistance
        accordingly.
      </p>
    </div>
    <p>
      Note: The Refund & Cancellation Policy is subject to the terms and conditions of the Phrazify Chrome Extension. Users are advised to
      review the terms and conditions for a complete understanding of their rights and obligations regarding refunds and cancellations.
    </p>
  </body>
</html>
`;

function RefundPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="layoutPhrazify__body">
      <div>{Parser().parse(rawHTML)}</div>
    </div>
  );
}

export default RefundPolicy;
