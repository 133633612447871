import React from 'react';

const CurrentPlanDetails = ({ planName, validTill, wordsLeft, lastPurchaseDate, isAboutToExpire }) => {
  const formatDate = (date) => {
    let arr = date.split('-');
    let dd = arr[2];
    let mm = arr[1];
    let yyyy = arr[0];
    return dd + '/' + mm + '/' + yyyy;
  };
  return (
    <div className="whiteWrapper">
      <div className="whiteWrapper__heading">Current Plan Details</div>
      <div className="whiteWrapper__body">
        <div className="whiteWrapper__grid">
          <div className="col">
            <label>Name</label>
            <span className="value">{planName}</span>
          </div>
          <div className="col">
            <label>Words Left</label>
            <span className="value">{wordsLeft.toLocaleString('en-IN')}</span>
          </div>
          <div className="col">
            <label>Validity</label>
            <span className="value">{formatDate(validTill)}</span>
          </div>
          <div className="col">
            <label>Purachsed On</label>
            <span className="value">{formatDate(lastPurchaseDate)}</span>
          </div>
          {isAboutToExpire && (
            <div className="col">
              {/* <label style={{ color: "red" }}>About to Expire</label> */}
              <span style={{ color: 'red' }} className="value">
                About to Expire
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CurrentPlanDetails;
