import React, { useEffect } from 'react';
import { Button, Card, Form, Input, message } from 'antd';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!location?.state?.user) {
      navigate('/forgot-password');
    }
  });

  const resetPasswordRequest = async (passwordResetData) => {
    try {
      let result = await axios.post(
        `${process.env.REACT_APP_PHRAZIFY_BASE_URL}/user/mutations/reset-password`,
        passwordResetData,
      );

      if (result.status === 200) {
        message.success(result.data.message);
        navigate('/signin');
      }
    } catch (error) {
      let loadingButton = document.getElementById('loadingButton');
      loadingButton.innerHTML = 'Submit';
      loadingButton.disabled = false;
      console.error(error);
      message.error(error?.response?.data?.message);
    }
  };

  const onFinish = (values) => {
    let loadingButton = document.getElementById('loadingButton');
    loadingButton.innerHTML = '<span class="spinner"></span>';
    loadingButton.style.backgroundImage = 'linear-gradient(to top, #1991eb, #2da1f8)';
    loadingButton.disabled = true;

    let { otp, password } = values;
    resetPasswordRequest({ otp, password, user: location.state.user });
  };
  const onFinishFailed = (errorInfo) => {};

  return (
    <>
      <div className="layoutPhrazify__body">
        <div className="main-container">
          <div className="loginWin">
            <div className="loginWin__imgWrap"></div>
            <div className="loginWin__signIn">
              <h1>Reset Password</h1>
              <Form
                name="basic"
                onFinish={onFinish}
                layout="vertical"
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="OTP"
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the OTP recieved on your mail!',
                    },
                  ]}
                >
                  <Input placeholder="Enter the OTP recieved on your mail " />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      pattern: new RegExp(/^\S*$/g),
                      message: 'No spaces allowed in the password!',
                    },
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    () => ({
                      validator(_, value) {
                        if (!value || value.length > 5) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Password length must be at least 6 characters long!'));
                      },
                    }),
                  ]}
                  hasFeedback
                >
                  <Input.Password placeholder="Enter your password" />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('The new password that you entered do not match!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirm your password" />
                </Form.Item>

                <Form.Item>
                  <button id="loadingButton" className="btn__primary" htmlType="submit">
                    Submit
                  </button>
                </Form.Item>
                <div
                  className="loginWin__signup"
                  onClick={() => {
                    navigate('/signin');
                  }}
                >
                  Recovered your account password? <a href="">Sign in</a>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
