import React from 'react';
import { Button, Card, Form, Input, message } from 'antd';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Bottombar from '../containers/PublicContainer/Bottombar';
import Navbar from '../containers/PublicContainer/Navbar';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const forgotPasswordRequest = async (emailJSON) => {
    try {
      let result = await axios.post(
        `${process.env.REACT_APP_PHRAZIFY_BASE_URL}/user/mutations/forgot-password`,
        emailJSON,
      );

      if (result.status === 200) {
        message.success(result.data.message);
        navigate('/reset-password', { state: { user: result.data.data.user } });
      }
    } catch (error) {
      let loadingButton = document.getElementById('loadingButton');
      loadingButton.innerHTML = 'Submit';
      loadingButton.disabled = false;
      console.error(error);
      message.error(error?.response?.data?.message);
    }
  };

  const onFinish = (values) => {
    let loadingButton = document.getElementById('loadingButton');
    loadingButton.innerHTML = '<span class="spinner"></span>';
    loadingButton.style.backgroundImage = 'linear-gradient(to top, #1991eb, #2da1f8)';
    loadingButton.disabled = true;
    forgotPasswordRequest(values);
  };
  const onFinishFailed = (errorInfo) => {};

  return (
    <>
      <div className="layoutPhrazify__body">
        <div className="main-container">
          <div className="loginWin">
            <div className="loginWin__imgWrap"></div>
            <div className="loginWin__signIn">
              <h1>Forgot Password</h1>
              <Form
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="E-mail"
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Please input your E-mail!',
                    },
                  ]}
                >
                  <Input placeholder="john@example.com" />
                </Form.Item>

                <Form.Item>
                  <button id="loadingButton" className="btn__primary" htmlType="submit">
                    Submit
                  </button>
                </Form.Item>
                <div
                  className="loginWin__signup"
                  onClick={() => {
                    navigate('/signin');
                  }}
                >
                  Remember your password? <a href="">Sign in</a>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
