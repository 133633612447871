import React, { useEffect, useState } from 'react';
import PricingCard from '../PricingCard/PricingCard';
import axiosInstance from '../../API/axios';
import { message } from 'antd';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { useLocation } from 'react-router-dom';
import LandingPricingCard from '../PricingCard/LandingPricingCard';

function AllPlans({ activePlanId }) {
  const location = useLocation();
  const [allPlans, setAllPlans] = useState([]);
  const [isUserCurrencyINR, setIsUserCurrencyINR] = useState(false);

  useEffect(() => {
    getAllPlans();
  }, []);

  useEffect(() => {
    let userCurrencyIsINR = secureLocalStorage.getItem('isUserCurrencyINR');
    if (userCurrencyIsINR === 'true') {
      setIsUserCurrencyINR(true);
      return;
    }
    if (userCurrencyIsINR === null) {
      userIpLookup();
    }
  }, []);

  const userIpLookup = async () => {
    try {
      let result = await axios.get(`https://api.db-ip.com/v2/free/self`);

      if (result.status === 200) {
        if (result.data.countryCode === 'IN') {
          setIsUserCurrencyINR(true);
          secureLocalStorage.setItem('isUserCurrencyINR', 'true');
        } else {
          secureLocalStorage.setItem('isUserCurrencyINR', 'false');
        }
        // }
      }
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data?.message);
    }
  };

  const getAllPlans = async () => {
    try {
      let result = await axios.get(`${process.env.REACT_APP_PHRAZIFY_BASE_URL}/plan/queries/all`);
      if (result.status === 200) {
        setAllPlans(result?.data?.data?.plans);
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    }
  };
  return (
    <>
      {location.pathname === '/' ? (
        <section className="landingPage__section pricing">
          <h1 className="section-heading">Pricing</h1>
          <p className="section-sub-heading">Unleash Limitless Possibilities with Phrazify's Diverse Pricing Plans.</p>

          <div className="pricing-wrapper">
            {allPlans &&
              allPlans.map((planDetails) => {
                if (planDetails.name !== 'Free Plan') {
                  return <LandingPricingCard planDetails={planDetails} isUserCurrencyINR={isUserCurrencyINR} />;
                }
              })}
          </div>

          {allPlans &&
            allPlans.map((planDetails) => {
              if (planDetails.name === 'Free Plan') {
                return (
                  <div className="free-forever">
                    <h4 className="free-forever__heading">{planDetails.name} Forever Plan</h4>
                    <div className="free-forever__description">
                      <p>Unleash your writing potential without spending a dime.</p>
                      <p>
                        Phrazify's {planDetails.name} offers up to {planDetails.wordsPerMonth.toLocaleString('en-IN')}{' '}
                        words per month, giving you a taste of our superior writing enhancements.
                        <br /> Ready to start?
                      </p>
                      <span className="link-btn">
                        Get Started For {planDetails.name} <em className="icon-ic-down-arrow"></em>
                      </span>
                    </div>
                  </div>
                );
              }
            })}
        </section>
      ) : (
        <div className="whiteWrapper">
          <div className="whiteWrapper__heading">All available plans</div>
          <div className="whiteWrapper__body">
            <div className="availablePlans">
              {allPlans &&
                allPlans.map((planDetails) => {
                  if (planDetails._id === activePlanId) {
                    return (
                      <PricingCard
                        planDetails={planDetails}
                        IsCurrentlyActive={true}
                        isUserCurrencyINR={isUserCurrencyINR}
                      />
                    );
                  } else if (
                    planDetails._id === '610000000000000000000000' &&
                    activePlanId !== '610000000000000000000000'
                  ) {
                    return <></>;
                  } else {
                    return (
                      <PricingCard
                        planDetails={planDetails}
                        IsCurrentlyActive={false}
                        isUserCurrencyINR={isUserCurrencyINR}
                      />
                    );
                  }
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AllPlans;
