import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import secureLocalStorage from "react-secure-storage";
import "../../styles/landingPage/landing.scss";
import { getCookie } from "../../util/cookies";

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = getCookie("phrazify-userId");
  const [mobileMenu, setMobileMenu] = useState(false);

  const triggerMobileNav = () => {
    setMobileMenu(!mobileMenu);
  };

  const launchApp = () => {
    navigate("/app");
  };

  const cleanTrigger = () => {
    setMobileMenu(false);
  };

  return (
    <>
      {location.pathname === "/" ? (
        <nav className="navbar">
          <span className="logo">
            <img src="https://phrazify.s3.ap-southeast-1.amazonaws.com/icons/logo.png" alt="Phrazify" style={{ maxWidth: "165px" }} />
          </span>

          <ul className={mobileMenu ? "navigation open" : "navigation"}>
            <NavHashLink smooth to="/#about" onClick={cleanTrigger}>
              <li>About</li>
            </NavHashLink>
            <NavHashLink smooth to="/#features" onClick={cleanTrigger}>
              <li>Features</li>
            </NavHashLink>
            <NavHashLink smooth to="/#pricing" onClick={cleanTrigger}>
              <li>Pricing</li>
            </NavHashLink>
            <NavHashLink smooth to="/#faqs" onClick={cleanTrigger}>
              <li>FAQs</li>
            </NavHashLink>
          </ul>

          <Link to="/signin">
            <button className="btn__primary signup-btn" style={{ width: "160px", fontSize: "13.33px" }}>
              {userId ? "Go to Dashboard" : "Sign Up / Sign In"}
            </button>
          </Link>
          <button className="mobile-hamburger" onClick={triggerMobileNav}>
            <em className="icon-ic-menu"></em>
          </button>
        </nav>
      ) : (location.pathname === "/app" ? (
        <div className="layoutPhrazify__nav">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img
              style={{ cursor: "pointer", maxWidth: "165px" }}
              src="https://phrazify.s3.ap-southeast-1.amazonaws.com/icons/logo-light.png"
              alt="website logo"
              onClick={() => {
                navigate("/");
              }}
            ></img>
          </div>
        </div>
      ) : (
        <div className="layoutPhrazify__nav">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img
              style={{ cursor: "pointer", maxWidth: "165px" }}
              src="https://phrazify.s3.ap-southeast-1.amazonaws.com/icons/logo-light.png"
              alt="website logo"
              onClick={() => {
                navigate("/");
              }}
            ></img>
            <button id="launchApp" onClick={launchApp} className="btn__primary" style={{ maxWidth: "150px" }}>
              Launch App
            </button>
          </div>
        </div>
      ))}
    </>
  );
}

export default Navbar;
