import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, message } from 'antd';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../App';
import secureLocalStorage from 'react-secure-storage';
import axiosInstance from '../API/axios';
import { getCookie, setCookie } from '../util/cookies';

const VerifyEmailPending = () => {
  let value = React.useContext(AuthContext);

  const [secondsLeft, setSecondsLeft] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const id = getCookie('phrazify-userId');
    if (id) {
      getUserDetails(id);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (secondsLeft > 0) {
        setSecondsLeft(secondsLeft - 1);
      }

      if (secondsLeft === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [secondsLeft]);

  const getUserDetails = async (userId) => {
    try {
      let result = await axiosInstance.get(`${process.env.REACT_APP_PHRAZIFY_BASE_URL}/user/queries/user?id=${userId}`);

      if (result.status === 200) {
        // setUserDetails(result?.data?.data?.user);
        if (!result?.data?.data?.user.emailVerified) {
          navigate('/verify-email-pending');
        } else if (result?.data?.data?.user.emailVerified) {
          navigate('/dashboard');
        }
      } else {
        navigate('/dashboard');
        secureLocalStorage.clear();
        setCookie('phrazify-accessToken', '', -1);
        setCookie('phrazify-userId', '', -1);
        setCookie('phrazify-refreshToken', '', -1);
      }
    } catch (error) {
      navigate('/dashboard');
      secureLocalStorage.clear();
      setCookie('phrazify-accessToken', '', -1);
      setCookie('phrazify-userId', '', -1);
      setCookie('phrazify-refreshToken', '', -1);
      // message.error(error?.response?.data?.message);
    }
  };

  const otpVerifyRequest = async () => {
    try {
      let result = await axiosInstance.post(
        `${process.env.REACT_APP_PHRAZIFY_BASE_URL}/user/mutations/send_verification_email`,
        // {
        // gRecaptchaResponse: token,}
      );

      if (result.status === 200) {
        message.success(result.data.message);
        setSecondsLeft(30);
      } else {
        message.error(result.data.message);
      }
    } catch (error) {
      let loadingButton = document.getElementById('loadingButton');
      loadingButton.innerHTML = 'Resend Verification Email';
      loadingButton.disabled = false;
      console.error(error);
      message.error(error?.response?.data?.message);
      setSecondsLeft(30);
    }
  };

  const onFinish = (values) => {
    let loadingButton = document.getElementById('loadingButton');
    loadingButton.innerHTML = '<span class="spinner"></span>';
    loadingButton.style.backgroundImage = 'linear-gradient(to top, #1991eb, #2da1f8)';
    loadingButton.disabled = true;

    let { otp } = values;
    otpVerifyRequest({ otp });
  };
  const onFinishFailed = (errorInfo) => {};

  return (
    <>
      <div className="layoutPhrazify__body">
        <div className="main-container">
          <div className="loginWin">
            <div className="loginWin__imgWrap"></div>
            <div className="loginWin__signIn" style={{ textAlign: 'center' }}>
              <h1>Verify Email</h1>
              <Form
                name="basic"
                onFinish={onFinish}
                layout="vertical"
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                {/* <Form.Item
                label="OTP"
                name="otp"
                rules={[
                  {
                    required: true,
                    message: 'Please input the OTP recieved on your mail!',
                  },
                ]}
              >
                <Input placeholder="Enter the OTP recieved on your mail " />
              </Form.Item> */}
                <div>
                  You need to verify your email to proceed.
                  <br />
                  Please check your inbox for verification email.
                </div>
                {/* <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    pattern: new RegExp(/^\S*$/g),
                    message: 'No spaces allowed in the password!',
                  },
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  () => ({
                    validator(_, value) {
                      if (!value || value.length > 5) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Password length must be at least 6 characters long!'));
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Enter your password" />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The new password that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm your password" />
              </Form.Item> */}

                {/* <Form.Item>
                <button id="loadingButton" class="btn__primary" htmlType="submit">
                  Submit
                </button>
              </Form.Item> */}
                <div
                  className="loginWin__signup"
                  // onClick={() => {
                  //   // navigate('/signin');
                  // }}
                  style={{ border: 'none' }}
                >
                  {secondsLeft === 0 ? (
                    <Form.Item>
                      <button id="loadingButton" className="btn__primary">
                        Resend Verification Email
                      </button>
                    </Form.Item>
                  ) : (
                    <>
                      You need to wait {secondsLeft}s before resending OTP
                      <Form.Item>
                        <button id="loadingButton" className="btn__primary" disabled>
                          Resend Verification Email
                        </button>
                      </Form.Item>
                    </>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmailPending;
