import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SignIn from './pages/Signin';
import SignUp from './pages/Signup';
import LandingPage from './pages/LandingPage';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import PrivateRoutes from './util/PrivateRoutes';
import PublicRoutes from './util/PublicRoutes';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import RefundPolicy from './pages/RefundPolicy';
import Dashboard from './routes/DashboardPage';
import Bottombar from './containers/PublicContainer/Bottombar';
import Navbar from './containers/PublicContainer/Navbar';
import VerifyEmailPending from './routes/VerifyEmailPending';
import VerifyEmail from './routes/VerifyEmail';
import PaymentConfirmation from './routes/PaymentConfirmation';
import WebApp from './components/WebApp';

export const AuthContext = React.createContext();

const App = () => {
  if (localStorage.getItem('isAuthenticated') === null) {
    localStorage.setItem('isAuthenticated', 'false');
  }
  let auth = localStorage.getItem('isAuthenticated');
  const [isAuthenticated, setIsAuthenticated] = useState(auth);

  return (
    <>
      <div className="layoutPhrazify">
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
          <Router>
            <Navbar />
            <Routes>
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/" element={<LandingPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/refund-policy" element={<RefundPolicy />} />
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route element={<PublicRoutes />}>
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
              </Route>
              <Route element={<PrivateRoutes />}>
                <Route path="/verify-email-pending" element={<VerifyEmailPending />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/payment-confirmation" element={<PaymentConfirmation />} />
                <Route path="/app" element={<WebApp />} />
              </Route>
            </Routes>
            <Bottombar />
          </Router>
        </AuthContext.Provider>
      </div>
    </>
  );
};

export default App;
