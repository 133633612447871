import React from 'react';
// import "../styles/landingPage/landing.scss"
import '../styles/landingPage/landing.css';
import '../styles/icons.css';
// import "../styles/landingPage/landing.css.map"
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import mainHeaderGraphic from '../assets/images/main-header-graphic.svg';
import imgInteractive from '../assets/images/img-interactive.svg';
import imgEmail from '../assets/images/img-email.svg';
import imgRealTime from '../assets/images/img-real-time.svg';
import imgCaption from '../assets/images/img-caption.svg';
import imgFilters from '../assets/images/img-filters.svg';
import imgPlans from '../assets/images/img-plans.svg';
import imgInterface from '../assets/images/img-interface.svg';
import imgPrivacy from '../assets/images/img-privacy.svg';
import AllPlans from '../components/AllPlans/AllPlans';
import { accordionData } from '../util/content';
import Accordion from '../components/Accordion/Accordion';
import { HashLink } from 'react-router-hash-link/dist/react-router-hash-link.cjs.production';
import { NavHashLink } from 'react-router-hash-link';

function LandingPage() {
  return (
    <div className="landingPage">
      {/* <nav className="navbar">
            <span className="logo"><img src="https://phrazify.s3.ap-southeast-1.amazonaws.com/icons/logo.png" alt="Phrazify" style={{ maxWidth: "200px" }}/></span>
        <ul className="navigation" style={{ display: "flex" }}>
        <NavHashLink smooth to="/#about">
            <li>About</li>
            </NavHashLink>
        <NavHashLink smooth to="/#features">
            <li>Features</li>
            </NavHashLink>
        <NavHashLink smooth to="/#pricing">
            <li>Pricing</li>
            </NavHashLink>
        <NavHashLink smooth to="/#faqs">
            <li>FAQs</li>
            </NavHashLink>
            </ul>

            <Link to='/signin'><button className="btn__primary signup-btn" style={{width:"160px", fontSize:"13.33px", backgroundImage:'none'}}>Sign Up / Sign In</button></Link>
            <button className="mobile-hamburger"><em className="icon-ic-regenerate"></em></button>
        </nav> */}
      <section id="mainSection" className="landingPage__section">
        <div className="two-section-wrapper">
          <div className="left-section main-banner-content">
            <h1 className="banner-heading">
              <span className="light">Enhance</span> Writing,
              <br />
              <span className="light">Ignite</span> Creativity
            </h1>
            <p className="banner-description">Unleash potential with AI assistance</p>

            <Link to="/signin">
              <button className="btn__primary" style={{ width: '160.63px', fontSize: '13.33px' }}>
                Try Phrazify Free
              </button>
            </Link>
            <span className="download_details">22,234 Downloads | 1780+ happy customers</span>
          </div>
          <div className="right-section">
            <img src={mainHeaderGraphic} alt="main banner" className="img-full" />
          </div>
        </div>
      </section>
      <div className="blue_bg">
        <div className="summary-strip">
          <div className="summary-strip__card">
            <h2 className="summary-strip__card__title">10M</h2>
            <p className="summary-strip__card__caption">Total words processed</p>
          </div>
          <div className="summary-strip__card">
            <h2 className="summary-strip__card__title">150K</h2>
            <p className="summary-strip__card__caption">Questions answered</p>
          </div>
          <div className="summary-strip__card">
            <h2 className="summary-strip__card__title">24K</h2>
            <p className="summary-strip__card__caption">Emails improved</p>
          </div>
          <div className="summary-strip__card">
            <h2 className="summary-strip__card__title">19K</h2>
            <p className="summary-strip__card__caption">Captions generated</p>
          </div>
        </div>
      </div>

      <section id="about" className="landingPage__section services">
        <div className="flex-40-60">
          <div className="col-1">
            <h1>Phrazify: Illuminating Your Words with AI-Powered Precision.</h1>
          </div>
          <div className="col-2">
            <p>
              Phrazify reshapes the boundaries of your writing capabilities, incorporating artificial intelligence to
              refine and enhance your words. From transforming raw thoughts into polished prose and emails to creating
              captivating social media captions, it offers a tailored writing experience. Phrazify is not just a tool,
              but your personal writing assistant, always ready to elevate your text to the next level.
            </p>
          </div>
        </div>

        <div className="services-wrapper">
          <div className="services-wrapper__card">
            <span className="services-wrapper__card__icon">
              <em className="icon-ic-user-friendly"></em>
            </span>
            <h4 className="services-wrapper__card__heading">User-friendly interface</h4>
            <p>
              Designed for effortless navigation and simple interaction, helping you improve your writing quickly and
              efficiently.
            </p>
          </div>
          <div className="services-wrapper__card">
            <span className="services-wrapper__card__icon">
              <em className="icon-ic-customizable"></em>
            </span>
            <h4 className="services-wrapper__card__heading">Customizable experience</h4>
            <p>
              Filters for English level, word limit, and tone allow you to tailor Phrazify's assistance to your specific
              needs.
            </p>
          </div>
          <div className="services-wrapper__card">
            <span className="services-wrapper__card__icon">
              <em className="icon-ic-ai"></em>
            </span>
            <h4 className="services-wrapper__card__heading">AI-powered</h4>
            <p>
              Uses sophisticated AI technology to provide accurate information, suggest improvements, and generate
              creative content.
            </p>
          </div>
          <div className="services-wrapper__card">
            <span className="services-wrapper__card__icon">
              <em className="icon-ic-diverse"></em>
            </span>
            <h4 className="services-wrapper__card__heading">Diverse applications</h4>
            <p>
              From polishing academic essays to crafting compelling social media captions, Phrazify has you covered.
            </p>
          </div>
          <div className="services-wrapper__card">
            <span className="services-wrapper__card__icon">
              <em className="icon-ic-affordable"></em>
            </span>
            <h4 className="services-wrapper__card__heading">Affordable plans</h4>
            <p>
              Multiple subscription tiers, including a free plan, offer flexibility and value to suit different user
              requirements.
            </p>
          </div>
          <div className="services-wrapper__card">
            <span className="services-wrapper__card__icon">
              <em className="icon-ic-secure"></em>
            </span>
            <h4 className="services-wrapper__card__heading">Secure and private</h4>
            <p>We respect your privacy and protect your data with robust security measures.</p>
          </div>
          <div className="services-wrapper__card">
            <span className="services-wrapper__card__icon">
              <em className="icon-ic-inclusive"></em>
            </span>
            <h4 className="services-wrapper__card__heading">Inclusive and accessible</h4>
            <p>
              Phrazify is for everyone who wants to improve their writing, whether you're a student, a professional, or
              someone perfecting English as a second language.
            </p>
          </div>
        </div>
      </section>
      <section id="features" className="landingPage__section features">
        <h1 className="section-heading blue">Features</h1>
        <p className="section-sub-heading">Explore the phrazify Landscape: Where Features Meet Creativity.</p>

        <div className="features-wrapper">
          <div className="features-wrapper__card">
            <div className="features-wrapper__card__image">
              <img src={imgInteractive} alt="Interactive text editing" className="img-full" />
            </div>
            <h4 className="features-wrapper__card__heading">Interactive text editing</h4>
            <p className="features-wrapper__card__content">
              Phrazify isn't just an editor, it's an interactive writing partner that dynamically improves your text in
              real-time.
            </p>
          </div>
          <div className="features-wrapper__card">
            <div className="features-wrapper__card__image">
              <img src={imgEmail} alt="Contextual email refinement" className="img-full" />
            </div>
            <h4 className="features-wrapper__card__heading">Contextual email refinement</h4>
            <p className="features-wrapper__card__content">
              Not just improving your grammar and syntax, but Phrazify ensures that your tone, language, and context
              align perfectly with the professional environment.
            </p>
          </div>
          <div className="features-wrapper__card">
            <div className="features-wrapper__card__image">
              <img src={imgRealTime} alt="Real-time query resolution" className="img-full" />
            </div>
            <h4 className="features-wrapper__card__heading">Real-time query resolution</h4>
            <p className="features-wrapper__card__content">
              Phrazify doesn't just answer questions, it provides comprehensive responses that cater to your curiosity
              in real-time.
            </p>
          </div>
          <div className="features-wrapper__card">
            <div className="features-wrapper__card__image">
              <img src={imgCaption} alt="Creative caption generation" className="img-full" />
            </div>
            <h4 className="features-wrapper__card__heading">Creative caption generation</h4>
            <p className="features-wrapper__card__content">
              Phrazify isn’t a mere caption generator, it’s your creative partner, creating social media captions that
              capture the essence of your thoughts, while adding a sprinkle of intrigue.
            </p>
          </div>
          <div className="features-wrapper__card">
            <div className="features-wrapper__card__image">
              <img src={imgFilters} alt="Adaptive content filters" className="img-full" />
            </div>
            <h4 className="features-wrapper__card__heading">Adaptive content filters</h4>
            <p className="features-wrapper__card__content">
              Phrazify offers a personalized writing experience with adaptive filters that tailor your content to align
              with your desired style, tone, and word count.
            </p>
          </div>
          <div className="features-wrapper__card">
            <div className="features-wrapper__card__image">
              <img src={imgPlans} alt="Flexible subscription models" className="img-full" />
            </div>
            <h4 className="features-wrapper__card__heading">Flexible subscription models</h4>
            <p className="features-wrapper__card__content">
              From casual writers to professional authors, Phrazify has subscription plans designed to meet diverse user
              needs, ensuring you always have the right words at the right time.
            </p>
          </div>
          <div className="features-wrapper__card">
            <div className="features-wrapper__card__image">
              <img src={imgInterface} alt="Sleek and intuitive design" className="img-full" />
            </div>
            <h4 className="features-wrapper__card__heading">Sleek and intuitive design</h4>
            <p className="features-wrapper__card__content">
              Phrazify isn’t just about powerful features, but also about a seamless user experience with a sleek and
              intuitive interface that’s easy on the eyes and simple to navigate.
            </p>
          </div>
          <div className="features-wrapper__card">
            <div className="features-wrapper__card__image">
              <img src={imgPrivacy} alt="Commitment to user privacy" className="img-full" />
            </div>
            <h4 className="features-wrapper__card__heading">Commitment to user privacy</h4>
            <p className="features-wrapper__card__content">
              Your words are personal, and Phrazify respects that. With a commitment to robust data security, Phrazify
              ensures your creativity remains confidential and private.
            </p>
          </div>
        </div>
      </section>

      <section id="pricing" className="landingPage__section pricing">
        <AllPlans />
      </section>

      <section id="faqs" className="landingPage__section faqs">
        <h1 className="section-heading blue">FAQs</h1>

        <div className="faqs-wrapper">
          {accordionData.map(({ title, content }) => (
            <Accordion title={title} content={content} />
          ))}
        </div>
      </section>
      {/* <div className="blue_bg">
            <div className="we-are-here">
                    <div className="we-are-here__heading">
                        <h2>We are here for you</h2>
                    </div>
                    <div className="we-are-here__details">
                        <p>Need support or have a suggestion? Email us at<br /> <a className="link" href="mailto:support@phrazify.com">support@phrazify.com</a></p>
                        <p className="small-txt">24-hour response commitment because we care</p>
                    </div>
            </div>
        </div>

        <footer className="footer">
            <div className="footer__logo">
                <img src={brandLogo} />
                <span className="footer__copyright">© All rights reserved to Phrazify 2023</span>
            </div>
            <ul className="footer__nav">
                <li>Privacy policy</li>
                <li>Terms of use</li>
                <li>Subscription policy</li>
            </ul>
        </footer> */}
    </div>
  );
}

export default LandingPage;
