import React from 'react';
import { Link } from 'react-router-dom';

const LandingPricingCard = ({ planDetails, isUserCurrencyINR }) => {
  let { name, wordsPerMonth, monthlyPriceINR, monthlyPriceDollar, description } = planDetails;
  return (
    <div className="pricing-wrapper__card">
      <h4 className="pricing-wrapper__card__heading">{name}</h4>
      <div className="pricing-wrapper__card__pricing">
        <sup>{isUserCurrencyINR ? '₹' : '$'}</sup>
        {isUserCurrencyINR ? monthlyPriceINR.toLocaleString('en-IN') : monthlyPriceDollar.toLocaleString('en-IN')}
        <span className="per-month">/month</span>
      </div>
      <div className="pricing-wrapper__card__description">
        {description.map(function (object, i) {
          return object.includes('words limit per month') ? (
            <p>
              <span style={{ fontSize: '1.3rem', fontWeight: '600' }}>{wordsPerMonth.toLocaleString('en-IN')}</span>{' '}
              <span>words limit per month.</span>
            </p>
          ) : (
            <p>{object}</p>
          );
        })}
      </div>
      <Link to="/signin">
        {name === 'Free Plan' ? (
          <button className="btn__pricing">Sign up</button>
        ) : (
          <button className="btn__pricing">Upgrade to {name}</button>
        )}
      </Link>
    </div>
  );
};

export default LandingPricingCard;
