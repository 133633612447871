import React from 'react';
import { Button, Card, Form, Input, message } from 'antd';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { AuthContext } from '../App';
import Bottombar from '../containers/PublicContainer/Bottombar';
import Navbar from '../containers/PublicContainer/Navbar';
import { setCookie } from '../util/cookies';

const SignIn = () => {
  const navigate = useNavigate();
  let value = React.useContext(AuthContext);
  const userSignIn = async (loginData) => {
    try {
      let result = await axios.post(`${process.env.REACT_APP_PHRAZIFY_BASE_URL}/user/mutations/signin`, loginData);
      if (result.status === 200) {
        message.success(result.data.message);
        message.success('Please login with same credentials on the extension.');
        setCookie('phrazify-accessToken', result.data.data.session.accessToken, 30);
        setCookie('phrazify-userId', result.data.data.user._id, 30);
        setCookie('phrazify-refreshToken', result.data.data.session.refreshToken, 30);
        // secureLocalStorage.setItem('name', result.data.data.user.name);
        // secureLocalStorage.setItem('email', result.data.data.user.email);
        // secureLocalStorage.setItem('emailVerified', result?.data?.data?.user.emailVerified);
        navigate('/dashboard');
        value.setIsAuthenticated('true');
        localStorage.setItem('isAuthenticated', 'true');
      }
    } catch (error) {
      let loadingButton = document.getElementById('loadingButton');
      loadingButton.innerHTML = 'Sign in';
      loadingButton.disabled = false;

      console.error(error);
      // message.error("Invalid Credentials");
      message.error(error?.response?.data?.message);
    }
  };

  const onFinish = (values) => {
    let loadingButton = document.getElementById('loadingButton');
    loadingButton.innerHTML = '<span class="spinner"></span>';
    loadingButton.disabled = true;

    userSignIn(values);
  };
  const onFinishFailed = (errorInfo) => {};

  return (
    <>
      <div className="layoutPhrazify__body">
        <div className="main-container">
          <div className="loginWin">
            <div className="loginWin__imgWrap"></div>
            <div className="loginWin__signIn">
              <h1>Sign In</h1>
              <Form
                name="basic"
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Please input your E-mail!',
                    },
                  ]}
                >
                  <Input placeholder="john@example.com" />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input.Password placeholder="Enter your password" />
                </Form.Item>

                <div
                  className="loginWin__forgotPassword"
                  onClick={() => {
                    navigate('/forgot-password');
                  }}
                >
                  Forgot Password?
                </div>

                <Form.Item>
                  <button id="loadingButton" className="btn__primary" htmlType="submit">
                    Sign in
                  </button>
                </Form.Item>
                <div
                  className="loginWin__signup"
                  onClick={() => {
                    navigate('/signup');
                  }}
                >
                  Don't Have an account? <a href="">Sign up</a>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
